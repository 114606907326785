import { searchLocation } from '@utils/location';
import { AWSLocationResponse } from '@v2/types';
import { useCallback, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

export const useLocationDropdown = (
  onLocationChange: (location: AWSLocationResponse) => void
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<AWSLocationResponse[]>([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isLocationDeniedOrBlocked, setIsLocationDeniedOrBlocked] =
    useState(false);
  const [userLocationItem, setUserLocationItem] =
    useState<AWSLocationResponse>();

  const debounceSearch = useDebounceCallback((searchValue: string) => {
    getAwsLocations(searchValue);
  }, 1500);

  const onChangeSearchInput = useCallback(async (value: string) => {
    if (!value.trimStart().length) setSearchResults([]);
    setSearchValue(value);
    if (value.trimStart().length < 3) return;
    debounceSearch(value.trimStart());
  }, []);

  const getAwsLocations = async (location: string) => {
    setShowErrorMessage(false);
    setIsLoading(true);
    const result = await searchLocation(location);
    if (!result) {
      setIsLoading(false);
      setShowErrorMessage(true);
      return;
    }

    const filterOnlyCities = result.filter(
      (item) =>
        item?.Categories?.includes('MunicipalityType') ||
        item?.Categories?.includes('NeighborhoodType') ||
        item?.Categories?.includes('StreetType')
    );

    if (!filterOnlyCities.length) {
      setShowErrorMessage(true);
      setIsLoading(false);
    }

    setSearchResults(filterOnlyCities);
    setIsLoading(false);
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
    setShowErrorMessage(false);
  };

  const onSelectLocation = (item: AWSLocationResponse) => {
    onLocationChange(item);
    clearSearch();
    setIsOpen(false);
  };

  const getUserLocation = async () => {
    setIsLoadingLocation(true);
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    const success = async (pos: GeolocationPosition) => {
      const crd = pos.coords;
      const result = await searchLocation(`${crd.latitude},${crd.longitude}`);

      if (!result[0].Text) {
        setIsLoadingLocation(false);
        return setIsLocationDeniedOrBlocked(true);
      }
      const item = { ...result, Text: result[0].Text };
      onSelectLocation(item);
      setIsLoadingLocation(false);
    };

    const errors = (err: GeolocationPositionError) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      setIsLoadingLocation(false);
      setIsLocationDeniedOrBlocked(true);
    };

    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === 'denied') {
          setIsLocationDeniedOrBlocked(true);
          setIsLoadingLocation(false);
        }
      });
    } else {
      setIsLocationDeniedOrBlocked(true);
      setIsLoadingLocation(false);
    }
  };

  return {
    isOpen,
    setIsOpen,
    searchValue,
    searchResults,
    showErrorMessage,
    isLoading,
    isLoadingLocation,
    isLocationDeniedOrBlocked,
    userLocationItem,
    onChangeSearchInput,
    getUserLocation,
    onSelectLocation,
    clearSearch,
  };
};
