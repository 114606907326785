import { CheckoutFormValues, PlainFareOrderData } from '@interfaces/tpro/order';
import { NextApiRequest, NextApiResponse } from 'next';

export type TPTarget = 'Test' | 'Production';

export enum Title {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Sir = 'Sir',
  Lord = 'Lord',
  Lady = 'Lady',
  Miss = 'Miss',
  Inf = 'Inf',
}

export enum Gender {
  FEMALE = 'F',
  MALE = 'M',
}

export type FlyingFare = 'Private' | 'Public' | 'WebFare';

export enum ChildTitle {
  Master = 'Master',
  Miss = 'Miss',
}

export interface TPError {
  ErrorCode: string;
  ErrorMessage: string;
}

export interface TPErrors {
  Errors: TPError | TPError[] | string;
}

export type BooleanString = 'true' | 'false';

export interface TPAuthResponse extends TPErrors {
  SessionId: string;
  SessionStatus?: BooleanString;
  Target: TPTarget;
}

export interface TPAuth {
  user_id: string;
  user_password: string;
  access: TPTarget;
  ip_address: string;
  session_id: string;
}

export type TPAuthPartial = Omit<
  TPAuth,
  'user_id' | 'user_password' | 'ip_address' | 'access'
>;

export interface TPBaseAuth {
  authenticate?: true;
  params?: Record<string, string | number>;
  session_id?: never;
  options?: never;
}

export interface FlyingSharedOptions {
  booking?: never;
  fare?: never;
  authenticate?: never;
  search?: never;
  flight?: never;
  params?: never;
  options?: never;
  details?: never;
  cancel?: never;
  order?: never;
  validateFare?: never;
}

export interface TPBase extends Pick<TPAuth, 'session_id'> {
  params?: Record<string, string>;
}

export type JourneyType = 'OneWay' | 'Return' | 'Circle';
export type FlyingClass = 'First' | 'Business' | 'Economy' | 'PremiumEconomy';

export interface FlightAvailabilitySearch {
  journey_type: JourneyType;
  airport_from_code: string;
  airport_to_code: string;
  departure_date: string /* YYYY-MM-DD */;
  return_date?: string /* YYYY-MM-DD */;
  adult_flight: string /* 1 */;
  child_flight?: string /* 1 */;
  infant_flight?: string /* 0 */;
  class?: FlyingClass;
  target?: TPTarget;
}

// flights search options
export interface TPFlightOptions
  extends Omit<TPBase, 'params'>,
    Omit<FlyingSharedOptions, 'params' | 'search'> {
  search: true;
  params: FlightAvailabilitySearch;
}

export interface FareRulesRequest
  extends TPAuthPartial,
    Omit<FlyingSharedOptions, 'params' | 'fare'> {
  fare: true;
  params: {
    fare_source_code: string;
  };
}

export type TPOptions =
  | TPFlightOptions
  | (TPBaseAuth & Omit<FlyingSharedOptions, 'authenticate'>)
  | FareRulesRequest
  | BookFlightData
  | TripDetailsRequest
  | TripOrderRequest
  | ValidateTripRequest;

export type TPAuthWithCookies = (
  req: NextApiRequest,
  res: NextApiResponse,
  flight?: boolean
) => Promise<Partial<TPAuthResponse>>;

interface FareInfo {
  FareInfo: {
    FareReference: string;
  };
}

export interface Fare {
  Amount: string /* "966.00" */;
  CurrencyCode: string /* "UAH" */;
  DecimalPlaces: string /* '2' */;
}

export interface Taxe extends Fare {
  TaxCode: string;
}

export interface FareBreakdown {
  FareBasisCode: string;
  PassengerFare: {
    BaseFare: Fare;
    EquivFare: Fare;
    ServiceTax: Fare;
    Taxes: Array<Taxe>;
    TotalFare: Fare;
  };
  PassengerTypeQuantity: {
    Code: PassengerType;
    Quantity: string /* '2' */;
  };
}

export interface AirItineraryFareInfo {
  DivideInPartyIndicator: BooleanString;
  FareSourceCode: string;
  FareInfos: Array<FareInfo>;
  FareType: FlyingFare;
  IsRefundable: 'No' | 'Yes';
  ItinTotalFares: {
    BaseFare: Fare;
    EquivFare: Fare;
    ServiceTax: Fare;
    TotalFare: Fare;
    TotalTax: Fare;
  };
  FareBreakdown: Array<FareBreakdown>;
  SplitItinerary: BooleanString;
}

export interface OriginDestinationOption {
  FlightSegment: {
    id: number;
    ArrivalAirportLocationCode: string;
    ArrivalDateTime: string /* 2021-08-10T19:25:00 */;
    CabinClassCode: string;
    CabinClassText: string;
    DepartureAirportLocationCode: string;
    DepartureDateTime: string /* 2021-08-10T18:50:00 */;
    Eticket: BooleanString;
    FlightNumber: string;
    JourneyDuration: string /* "95" */;
    distanceBetweenAirports: string /* "689.46" */;
    distanceUnit: 'KM' | 'MI';
    MarketingAirlineCode: string;
    MarriageGroup: string;
    MealCode: string;
    OperatingAirline: {
      Code: string;
      Equipment: string;
      FlightNumber: string;
    };
    status: string;
  };
  ResBookDesigCode: string;
  ResBookDesigText: string;
  SeatsRemaining: {
    BelowMinimum: BooleanString;
    Number: string /* "7" */;
  };
  StopQuantity: string /* '0' */;
  StopQuantityInfo: {
    ArrivalDateTime: string /* "0001-01-01T00:00:00" */;
    DepartureDateTime: string /* "0001-01-01T00:00:00" */;
    Duration: string /* "0" */;
    LocationCode: string /* "0" */;
  };
}

export interface OriginDestinationOptions {
  TotalStops: number;
  OriginDestinationOption: Array<OriginDestinationOption>;
}

export interface FareItinerary {
  FareItinerary: {
    AirItineraryFareInfo: AirItineraryFareInfo;
    DirectionInd: JourneyType;
    IsPassportMandatory: BooleanString;
    OriginDestinationOptions: Array<OriginDestinationOptions>;
    SequenceNumber: string;
    TicketType: 'eTicket' | string;
    ValidatingAirlineCode: string;
  };
}

export interface BaggageInfo {
  Arrival: string /* 'AUH' */;
  Baggage: string /* '2P' */;
  Departure: string /* 'BLR' */;
  FlightNo: string /* 'EY4082' */;
}

export interface FareRule {
  FareRule: {
    Airline: string /* It specifies the operating airline Code BA */;
    CityPair: string /* It specifies the origin and destination airport codes DELDXB */;
    Category: string;
    Rules: string;
  };
}

export interface FareRulesResponse {
  FareRules1_1Response: {
    FareRules1_1Result: {
      BaggageInfos: BaggageInfo[];
      FareRules: FareRule[];
    };
  };
}

export interface Child {
  child_flight: string;
  child_dob: string /* (Syntax:YYYY:MM:DDT00:00:00) */;
  child_gender: Gender | string;
  child_title: ChildTitle | string;
  child_first_name: string;
  child_last_name: string /* child passport issuance country */;
  child_passport_expiry_date: string /* (2011-02-04) child passport expiry date */;
  child_passport_no: string;
  child_frequentFlyrNum: string;
  childMealplan?: string;
  child_extras?: string /* ** ( 2 ) */;
  child_extras_inbound?: string /* ** ( 1 ) */;
}

export interface Infant {
  infant_flight: string;
  infant_dob: string /* (Syntax:YYYY:MM:DDT00:00:00) */;
  infant_gender: Gender | string;
  infant_title: ChildTitle | string;
  infant_first_name: string;
  infant_last_name: string /* child passport issuance country */;
  infant_passport_expiry_date: string /* (2011-02-04) child passport expiry date */;
  infant_passport_no: string;
  infant_frequentFlyrNum: string;
  infantMealplan?: string;
}

export interface BookingMethodRequestParamsBase {
  area_code: number /* 758 */;
  country_code?: number | string /* 0044 */;
  mobile_no: string | number /* 44072749 */;

  first_name: string /* * */;
  last_name: string /* * */;
  title: Title /* * */;
  email_id?: string;
  dob: string /* * */ /* Date of Birth.(Syntax:YYYY:MM:DDT00:00:00) */;
  gender: Gender /* * */;
  issue_country: string /* * */ /* 2 chars code */;
  passport_expiry: string /* * */ /* (2013-02-03) the passengers passport Expiry date */;
  passport_no: string /* * */ /* passenger’s passport number */;
  nationality?: string /* * */ /* 2 chars code */;
  type: FlyingFare;
  IsPassportMandatory?: boolean;
  adult_flight: string;
  frequentFlyrNum: string /* (BA4356789) passenger flyer number preceding with the two letters IATA airline code. */;
  fare_source_code: string /* fare/flight option identification code */;
  adultmealplan?: string;
  PostCode?: string;
  adult_extras?: string /* ** ( 1 ) */;
  adult_extras_inbound?: string /* **  ( 2 ) */;

  child_flight?: never;
  infant_flight?: never;
}

interface BookingMethodRequestChild
  extends Child,
    Omit<BookingMethodRequestParamsBase, 'child_flight'> {}

interface BookingMethodRequestInfant
  extends Infant,
    Omit<BookingMethodRequestParamsBase, 'infant_flight'> {}

interface BookingMethodRequestAll
  extends Child,
    Infant,
    Omit<BookingMethodRequestParamsBase, 'infant_flight' | 'child_flight'> {}

export type BookingMethodRequestParams =
  | Omit<
      BookingMethodRequestParamsBase,
      keyof Exclude<Child | Infant, BookingMethodRequestParamsBase>
    >
  | BookingMethodRequestChild
  | BookingMethodRequestInfant
  | BookingMethodRequestAll;

export interface BookingMethodRequest
  extends TPAuth,
    Omit<FlyingSharedOptions, 'booking' | 'params'> {
  booking: true;
  access: TPTarget;
  target: TPTarget;
  params: BookingMethodRequestParams;
}

export interface BookFlightData
  extends Omit<
    BookingMethodRequest,
    'user_id' | 'user_password' | 'ip_address'
  > {}

/*

1. * For more than one passenger, all passengers should send as String separated by <br>

2. **Important Notes

 Texts marked with ** are the additional parameters required for requesting extra services.
 ExtraServices are application only on LCC flights.
 Please read the Behavior of each ExtraServices to identify weather the service is applicable to each passenger individually or to the group.
 If the Behavior is GROUP_PAX then the service is applicable to entire group and you have to pass that service in the adult_extras parameter
 with first passenger only and it will be applicable to entire group.
 If the Behavior is PER_PAX, then you are only required to send adult_extras for the adult passengers and child_extras for child passengers.
 If the Behavior of the extra service is PER_PAX_OUTBOUND, PER_PAX_INBOUND or GROUP_PAX_OUTBOUND, GROUP_PAX_INBOUND, then you are required
 to send adult_extras for passenger’s outbound extra service and adult_extras_inbound for passenger’s inbound extra service for the adult
 passengers and child_extras for child passenger’s outbound extra service and child_extras_inbound for child passenger’s inbound extra service.
 If any of the parameters are not passed in the booking request that means no ExtraService is requested. However if you want to pass for
 any specific passenger, you can pass it by sending 0 for other passengers.
 For more than one passenger, for all passengers ServiceId should be passed as String separated by <br> in order to send properly for each
 passenger. For example: In PER_PAX case, for first passenger you don’t require extra service and for second passenger you need to request
 service with ServiceId 2, then request would be “ adult_extras : 0<br>2 “.
 Price that you would receive for the extra service is an addon cost that you would’ve to charge your end customer.
 For Eg: total fare for an itinerary is: $300
 Extra Service cost: $30
 So the total cost needed to charge will be $330 which cannot be shown to you as we have no idea which extra service is chosen by the passenger.
 In the book request you would need to add the service id so we know what is the service that we had to pass on to the airline.
*/

export interface ValidateTripRequest
  extends TPAuthPartial,
    Omit<FlyingSharedOptions, 'params' | 'validateFare'> {
  validateFare: true;
  session_id: string;
  params: {
    access: TPTarget;
    fare_source_code: string;
  };
}

export interface ValidateTripResonse {
  AirRevalidateResponse: {
    AirRevalidateResult: {
      IsValid: BooleanString;
      FareItineraries: FareItinerary;
    };
  };
}

export interface TripDetailsRequest
  extends TPAuthPartial,
    Omit<FlyingSharedOptions, 'params' | 'details'> {
  details: true;
  session_id: string;
  params: {
    UniqueID: string /* specify the API booking reference unique ID e.g.TR0000341 */;
    target: TPTarget;
    access: TPTarget;
  };
}

export interface TripOrderRequest
  extends TPAuthPartial,
    Omit<FlyingSharedOptions, 'params' | 'order'> {
  order: true;
  session_id: string;
  params: {
    UniqueID: string /* specify the API booking reference unique ID e.g.TR0000341 */;
    target: TPTarget;
    access: TPTarget;
    area_code: number;
  };
}

export enum PassengerType {
  ADULT = 'ADT',
  CHILD = 'CHD',
  INFANT = 'INF',
}

interface CustomerInfo {
  CustomerInfo: {
    PassengerType: PassengerType;
    PassportNumber: string;
    PassengerFirstName: string;
    PassengerLastName: string;
    PassengerTitle: 'MR' | Title | string;
    ItemRPH: string /* 1 */;
    eTicketNumber: string /* 8794521632284 */;
    DateOfBirth: string /* 1994-02-14T00:00:00 */;
    EmailAddress: string;
    Gender: Gender;
    PassengerNationality: string;
    PhoneNumber: string;
    PostCode: string;
  };
}

interface ReservationItem {
  ReservationItem: {
    AirEquipmentType: string;
    AirlinePNR: string;
    ArrivalAirportLocationCode: string;
    ArrivalDateTime: string /* 2018-07-01T09:15:00 */;
    ArrivalTerminal: string;
    Baggage: string;
    CabinClassText: string;
    DepartureAirportLocationCode: string;
    DepartureDateTime: string /* 2018-07-01T09:15:00 */;
    DepartureTerminal: string;
    FlightNumber: string;
    ItemRPH: string /* 1 */;
    JourneyDuration: string /* 60 */;
    MarketingAirlineCode: string;
    NumberInParty: string /* 1 */;
    OperatingAirlineCode: string;
    ResBookDesigCode: string;
    StopQuantity: string /* 0 */;
  };
}

interface ItineraryPricing {
  EquiFare: Fare;
  ServiceTax: Fare;
  Tax: Fare;
  TotalFare: Fare;
}

interface TripDetailsPTCFareBreakdown {
  PassengerTypeQuantity: {
    Code: string;
    Quantity: string /* 1 */;
  };
  TripDetailsPassengerFare: ItineraryPricing;
}

export interface ItineraryInfo {
  CustomerInfos: CustomerInfo[];
  ItineraryPricing: ItineraryPricing;
  ReservationItems: ReservationItem[];
  TripDetailsPTC_FareBreakdowns: TripDetailsPTCFareBreakdown[];
}

export interface TravelItinerary {
  BookingStatus: string /* "Booked" */;
  CrossBorderIndicator: BooleanString;
  Destination: string;
  FareType: FlyingFare;
  IsCommissionable: BooleanString;
  IsMOFare: BooleanString;
  ItineraryInfo: ItineraryInfo;
  UniqueID: string;
  Origin: string;
  TicketStatus: 'Ticketed';
}

export interface TripDetailsResult {
  TripDetailsResult: {
    Success: BooleanString;
    Target: TPTarget;
    TravelItinerary: TravelItinerary;
  };
}

export interface TripDetailsResponse {
  TripDetailsResponse: TripDetailsResult;
}

export interface CancelBookingResponse {
  CancelBookingResponse: CancelBookingResult;
}

interface CancelBookingResult {
  CancelBookingResult: {
    Errors: TPError | string;
    Success: 'true' | 'false';
    Target: TPTarget;
    UniqueID: string;
  };
}

interface FlyingReqOptions {
  search?: boolean;
  booking?: boolean;
  cancel?: boolean;
  validateFlight?: boolean;
  session_id: string;
}

export type FlyingData = [PlainFareOrderData, PlainFareOrderData?];

export interface FlyingOrderData {
  flyingPeople: CheckoutFormValues[];
  flyingData: FlyingData;
}

export interface FlyingReqBody extends FlyingReqOptions {
  bookFlightData: BookingMethodRequestParams;
}

export interface CancelFlightReqBody extends FlyingReqOptions {
  params: {
    UniqueID: string;
  };
}
