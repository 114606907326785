'use client';

import routes from '@config/routes';
import instance from '@instance';
import { LoggedInUserProps } from '@interfaces/auth';
import { passwordRegex, sendVerificationCode } from '@v2/action';
import {
  AlertToast,
  AlertTriangle,
  Button,
  CheckCircle,
  Input,
  Label,
  Typography,
} from '@v2/ui';
import { useFormik } from 'formik';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { ItemWrapper } from '../../../components/ContentWrapper';

export function Security({ user }: { user: LoggedInUserProps }) {
  const [isEditMode, setIsEditMode] = useState(false);

  const viewContent = (
    <div className="flex flex-col gap-y-1">
      <Typography size="tsm" className="text-gray-500">
        Password
      </Typography>
      <Typography size="tmd" className="font-semibold">
        ••••••••••
      </Typography>
    </div>
  );
  return (
    <>
      <ItemWrapper
        title="Security"
        trigger={
          <Button
            variant="link"
            className="p-0"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {isEditMode ? 'Cancel' : 'Update'}
          </Button>
        }
      >
        {isEditMode ? (
          <EditMode onCancelEditMode={() => setIsEditMode(false)} user={user} />
        ) : (
          viewContent
        )}
      </ItemWrapper>
    </>
  );
}
export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string().min(8).matches(passwordRegex).required('Required'),
  newPasswordConfirm: Yup.string()
    .min(6)
    .required('Required')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords do not match.')
    .label('repeat password'),
});

function EditMode({
  onCancelEditMode,
  user,
}: {
  onCancelEditMode: () => void;
  user: LoggedInUserProps;
}) {
  const router = useRouter();
  const {
    token: { refreshToken },
    email,
  } = user;
  const [loading, setLoading] = useState(false);
  const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      validateOnChange: true,
      validationSchema: changePasswordSchema,
      onSubmit: async (data) => {
        try {
          setLoading(true);

          const body = JSON.stringify({ ...data });
          const response = await instance.server(routes.updatePassword, {
            method: 'PATCH',
            body,
            headers: {
              Authorization: `Bearer ${user?.token?.accessToken}`,
            },
          });
          if (response.ok) {
            onCancelEditMode();
            toast.custom(
              (t) => (
                <AlertToast
                  {...t}
                  title="Success"
                  content={'Password has been reset'}
                  icon={<CheckCircle />}
                />
              ),
              { position: 'top-right' }
            );
          } else {
            const message = await response.json();
            toast.custom(
              (t) => (
                <AlertToast
                  {...t}
                  title="Oops, something went wrong"
                  content={message || 'Please try again in a minute.'}
                  icon={<AlertTriangle />}
                />
              ),
              { position: 'top-right' }
            );
            // errorToast(message ?? response.statusText);
          }
        } catch (e: any) {
          toast.custom(
            (t) => (
              <AlertToast
                {...t}
                title="Oops, something went wrong"
                content={'Please try again in a minute.'}
                icon={<AlertTriangle />}
              />
            ),
            { position: 'top-right' }
          );
        } finally {
          setLoading(false);
        }
      },
    });

  const handleForgotPasswordRedirect = () => {
    sendVerificationCode({ email });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="flex w-full flex-col gap-y-4">
        <div className="f grid w-full items-center gap-1.5">
          <Label htmlFor="oldPassword">Old password</Label>
          <Input
            value={values.oldPassword}
            onChange={handleChange}
            required
            name="oldPassword"
            type="text"
            id="oldPassword"
            onBlur={handleBlur}
            isError={errors.oldPassword !== undefined && touched.oldPassword}
          />
        </div>
        <div className="f grid w-full items-center gap-1.5">
          <Label htmlFor="newPassword">New password</Label>
          <Input
            name="newPassword"
            type="text"
            id="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            required
            onBlur={handleBlur}
            isError={errors.newPassword !== undefined && touched.newPassword}
            errorText={errors.newPassword}
          />
        </div>
        <div className="f grid w-full items-center gap-1.5">
          <Label htmlFor="newPasswordConfirm">Confirm password</Label>
          <Input
            name="newPasswordConfirm"
            type="text"
            id="newPasswordConfirm"
            value={values.newPasswordConfirm}
            onChange={handleChange}
            required
            onBlur={handleBlur}
            isError={
              errors.newPasswordConfirm !== undefined &&
              touched.newPasswordConfirm
            }
            errorText={errors.newPasswordConfirm}
          />
        </div>
      </div>

      <div className="mt-5 space-x-2">
        <Button type="submit" isLoading={loading}>
          Save
        </Button>
        <Link
          onClick={handleForgotPasswordRedirect}
          href={`/auth/forgot-password?email=${email}`}
          className="ml-[28px] text-base font-semibold text-primary-500"
        >
          Forgot your password?
        </Link>
      </div>
    </form>
  );
}
