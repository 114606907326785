'use client';
import routes from '@config/routes';
import { useLocationDropdown } from '@hooks/use-location-dropdown';
import instance from '@instance';

import { LoggedInUserProps } from '@interfaces/auth';
import { AWSLocationResponse } from '@v2/types';
import {
  AlertToast,
  AlertTriangle,
  Badge,
  Button,
  CheckCircle,
  Close,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  Input,
  SearchMD,
} from '@v2/ui';
import { MarkerPin01, NavigationPointerOff02 } from '@v2/ui/Icon/MapsAndTravel';
import { Spinner } from '@v2/ui/Icon/Spinner/Spinner';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { ItemWrapper } from '../../../components/ContentWrapper';
import { DEFAULT_PLACEHOLDER } from '../util';
import { dateStringToDateFormat } from '@utils/dates';

type UserProps = {
  user: LoggedInUserProps;
};

export function Location({ user }: { user: LoggedInUserProps }) {
  const [isEditMode, setIsEditMode] = useState(false);

  const viewContent = (
    <Badge>
      <MarkerPin01 size="20" className="mr-2.5 flex-shrink-0 text-gray-500" />

      {user?.location ?? DEFAULT_PLACEHOLDER}
    </Badge>
  );
  return (
    <>
      <ItemWrapper
        title="Location"
        trigger={
          <Button
            variant="link"
            className="p-0"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {isEditMode ? 'Cancel' : 'Edit'}
          </Button>
        }
      >
        {isEditMode ? (
          <EditMode
            user={user}
            defaultLocation={user?.location}
            onCancelEditMode={() => setIsEditMode(false)}
          />
        ) : (
          viewContent
        )}
      </ItemWrapper>
    </>
  );
}

function EditMode({
  user,
  onCancelEditMode,
  defaultLocation,
}: UserProps & {
  onCancelEditMode: () => void;
  defaultLocation: string | null;
}) {
  const [selectedLocation, setSelectedLocation] =
    useState<AWSLocationResponse | null>(defaultLocation || null);
  const [isLoading, setLoading] = useState(false);

  const handleChangeLocation = useCallback((location: AWSLocationResponse) => {
    setSelectedLocation(location);
  }, []);

  const { data, update } = useSession();

  const token = data?.user.token.accessToken;

  const router = useRouter();

  let dateOfBirthShort;

  if (user.dateOfBirth) {
    dateOfBirthShort = /[a-zA-Z]/.test(user.dateOfBirth || '')
      ? ''
      : dateStringToDateFormat(user.dateOfBirth || '');
  }

  const handleAddLocation = async () => {
    try {
      setLoading(true);

      const response = await instance.server(routes.addLocation, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          location: selectedLocation?.Text,
        }),
      });
      if (response.ok) {
        const userData = {
          id: user.id,
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          dateOfBirth: dateOfBirthShort,
          gender: user.gender,
          imageUrl: user.imageUrl,
          location: selectedLocation?.Text,
        };
        await update({ ...userData });
        onCancelEditMode();
        router.refresh();
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Success"
              content={'User location has been added '}
              icon={<CheckCircle />}
            />
          ),
          { position: 'top-right' }
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
        // router.refresh()
      } else {
        const { message } = await response.json();

        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Oops, something went wrong"
              content={message || 'Please try again in a minute.'}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      }
    } catch (e: any) {
      console.log(e);
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    } finally {
      setLoading(false);
    }

    return;
  };

  return (
    <div className="flex w-full flex-col space-y-5">
      <EventLocationDropdown
        onLocationChange={handleChangeLocation}
        selectedLocation={selectedLocation}
      />

      <Button
        isLoading={isLoading}
        className="self-start"
        disabled={!selectedLocation}
        onClick={handleAddLocation}
      >
        Save changes
      </Button>
    </div>
  );
}

interface Props {
  onLocationChange: (location: AWSLocationResponse) => void;
  selectedLocation?: AWSLocationResponse;
}

export const EventLocationDropdown = ({
  selectedLocation,
  onLocationChange,
}: Props) => {
  const {
    isOpen,
    setIsOpen,
    searchValue,
    searchResults,
    showErrorMessage,
    isLoading,
    isLoadingLocation,
    isLocationDeniedOrBlocked,
    userLocationItem,
    onChangeSearchInput,
    getUserLocation,
    onSelectLocation,
    clearSearch,
  } = useLocationDropdown(onLocationChange);

  return (
    <div className={'w-full'}>
      <DropdownMenu
        open={isOpen}
        onOpenChange={(open) => setIsOpen(open)}
        modal={false}
      >
        <DropdownMenuTrigger asChild className="w-full">
          <Input
            extent="md"
            placeholder="Search  by city..."
            value={selectedLocation?.Text || ''}
            className="w-full text-start"
          />
        </DropdownMenuTrigger>

        <DropdownMenuPortal>
          <DropdownMenuContent
            align={'start'}
            className="rounded-xl bg-white shadow-xl"
          >
            <div className="flex h-16 items-center justify-between gap-5 border-b border-gray-200 px-5 text-gray-500">
              <SearchMD />
              <input
                className="h-full min-w-64 flex-1 outline-none md:min-w-72"
                placeholder="Search by city"
                type="text"
                value={searchValue}
                onChange={(e) => onChangeSearchInput(e.target.value)}
              />
              <button onClick={() => setIsOpen(false)}>
                <Close />
              </button>
            </div>
            {showErrorMessage && (
              <div className="flex flex-col items-center justify-center gap-2 py-10">
                <MarkerPin01 size="24" className="text-gray-500" />
                <h3 className="font-semibold text-gray-500">
                  No locations found
                </h3>
                <p className="w-full max-w-80 text-center text-gray-500">
                  We couldn&apos;t find this location. Please select another
                  location or try again later.
                </p>
              </div>
            )}
            {isLoading && (
              <div className="flex w-full flex-col items-center justify-center gap-2 py-12">
                <div role="status">
                  <Spinner size="24" />
                </div>
                <p className="w-full max-w-80 text-center text-sm text-gray-500">
                  Loading locations
                </p>
              </div>
            )}
            {searchResults.map((item) => (
              <DropdownMenuItem
                key={item.PlaceId}
                onSelect={() => onSelectLocation(item)}
                className="flex h-16 w-full cursor-pointer items-center gap-3 px-6 py-3 font-semibold text-gray-800 outline-none hover:bg-gray-100"
              >
                {item.Text}
              </DropdownMenuItem>
            ))}
            {(!searchValue || !searchResults.length || isLoading) && (
              <button
                disabled={isLoadingLocation || isLocationDeniedOrBlocked}
                onClick={getUserLocation}
                className="flex h-16 w-full cursor-pointer items-center gap-3 border-t border-gray-200 px-6 py-3 font-semibold text-gray-800 outline-none hover:bg-gray-100"
              >
                {isLoadingLocation && (
                  <>
                    <Spinner size="24" />
                    Loading location
                  </>
                )}
                {!isLoadingLocation && isLocationDeniedOrBlocked && (
                  <>
                    <NavigationPointerOff02 />
                    Your location is not available
                  </>
                )}
                {!isLoadingLocation && !isLocationDeniedOrBlocked && (
                  <>
                    <MarkerPin01 />
                    {userLocationItem?.Text.split(', ')[3] || `Use my location`}
                  </>
                )}
              </button>
            )}
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenu>
    </div>
  );
};
