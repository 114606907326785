'use client';
import routes from '@config/routes';
import { type LoggedInUserProps } from '@interfaces/auth';
import { ItemWrapper } from '../../../components/ContentWrapper';

import { AFFILIATE_VISIT_COOKIE } from '@components/page-tracking/tracking-client';
import instance from '@instance';
import { v2Links } from '@utils/navigation/links';
import {
  AlertToast,
  AlertTriangle,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  typographyVariants,
} from '@v2/ui';
import { cn } from '@v2/utils';
import { deleteCookie } from 'cookies-next';
import { signOut } from 'next-auth/react';
import { useState } from 'react';
import toast from 'react-hot-toast';

export function Manage({ user }: { user: LoggedInUserProps }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteAccount = async () => {
    try {
      setLoading(true);
      const response = await instance.server(routes.deleteAccount, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${user.token.accessToken}`,
        },
      });

      if (response.ok) {
        setOpen(false);
        await signOut({
          callbackUrl: v2Links.home,
        });

        deleteCookie(AFFILIATE_VISIT_COOKIE);
      } else {
        const { message } = await response.json();
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Oops, something went wrong"
              content={message ?? response.statusText}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      }
    } catch (e: any) {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={e?.message}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    }

    setLoading(false);
  };

  return (
    <>
      <ItemWrapper
        className="mb-0 border-transparent"
        title="Manage"
        trigger={
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
              {' '}
              <Button variant="link" className="p-0">
                Delete account
              </Button>
            </DialogTrigger>
            <DialogContent className="gap-10">
              <DialogHeader>
                <DialogTitle
                  className={cn(
                    typographyVariants({ size: 'dsm' }),
                    'text-center font-bold'
                  )}
                >
                  Do you really want to delete your account?
                </DialogTitle>
              </DialogHeader>

              <DialogFooter className="w-full justify-start">
                <Button
                  className="w-full"
                  variant="secondary"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="w-full"
                  isLoading={loading}
                  onClick={deleteAccount}
                >
                  Delete account
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        }
      >
        <></>
      </ItemWrapper>
    </>
  );
}
