import routes from '@config/routes';
import instance, { apiFetcher } from '@instance';

export async function getUtcOffset(placeId) {
  const res = await instance.api(routes.getUtcOffset, {
    body: JSON.stringify({ placeId }),
  });
  return res.json();
}

export async function searchLocation(location) {
  return apiFetcher(routes.geocodeAws, {
    body: JSON.stringify({ location }),
  });
}

export async function getLatLng(placeId) {
  return apiFetcher(routes.getLatLngAws, {
    body: JSON.stringify({ placeId }),
  });
}
