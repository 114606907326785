'use client';
import { type LoggedInUserProps } from '@interfaces/auth';
import { Button, Input, Label, PhoneInput, Typography } from '@v2/ui';
import { useState } from 'react';
import { ItemWrapper } from '../../../components/ContentWrapper';
import { DEFAULT_PLACEHOLDER } from '../util';

export function ViewMode({ user }: { user: LoggedInUserProps }) {
  return (
    <div className="flex w-full flex-col justify-between gap-y-5 md:flex-row">
      <div className="flex flex-col gap-y-1">
        <Typography size="tsm" className="text-gray-500">
          Email
        </Typography>
        <Typography size="tmd" className="font-semibold">
          {user.email ?? DEFAULT_PLACEHOLDER}
        </Typography>
      </div>
      <div className="flex flex-col gap-y-1">
        <Typography size="tsm" className="text-gray-500">
          Phone number
        </Typography>
        <Typography size="tmd" className="font-semibold">
          {user.phoneNumber || DEFAULT_PLACEHOLDER}
        </Typography>
      </div>
      <div className="flex flex-col gap-y-1">
        <Typography size="tsm" className="text-gray-500">
          Emergency contact
        </Typography>
        <Typography size="tmd" className="font-semibold">
          {user?.emergencyNumbers?.length
            ? user?.emergencyNumbers[0].phoneNumber
            : DEFAULT_PLACEHOLDER}
        </Typography>
      </div>
    </div>
  );
}
export function Contact({ user }: { user: LoggedInUserProps }) {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <>
      <ItemWrapper
        title="Contact"
        trigger={
          // <Button
          //   variant="link"
          //   className="p-0"
          //   onClick={() => setIsEditMode(!isEditMode)}
          // >
          //   {isEditMode ? 'Cancel' : 'Edit'}
          // </Button>
          <div />
        }
      >
        {isEditMode ? <EditMode /> : <ViewMode user={user} />}
      </ItemWrapper>
    </>
  );
}

function EditMode() {
  const [loading, setLoading] = useState();

  const [value, setValue] = useState();
  return (
    <div className="w-full">
      <div>
        <form
          action="post"
          // onSubmit={handleSubmit}
          className="flex w-full flex-col gap-y-5"
        >
          <div className="flex w-full flex-col items-start gap-y-4 md:flex-row md:gap-x-4 md:gap-y-0">
            <div className="f grid w-full items-center gap-1.5">
              <div className="space-y-3">
                <Typography size="tlg" className="font-bold text-gray-800">
                  Email
                </Typography>
                <Label htmlFor="newEmail">New email </Label>
              </div>
              <Input name="newEmail" type="text" id="newEmail" required />
            </div>

            <div className="f grid w-full items-center gap-1.5">
              <div className="space-y-3">
                <Typography size="tlg" className="font-bold text-gray-800">
                  Phone
                </Typography>
                <Label htmlFor="newEmail">New phone number </Label>
              </div>
              <PhoneInput
                international
                focusInputOnCountrySelection
                value={value}
                // onChange={setValue}
                defaultCountry="US"
              />
            </div>
          </div>
          <div className="flex w-full flex-col items-start gap-y-4 md:flex-row md:gap-x-4 md:gap-y-0">
            <div className="f grid w-full items-center gap-1.5">
              <div className="space-y-3">
                <Typography size="tlg" className="font-bold text-gray-800">
                  Emergency contact
                </Typography>
                <Label htmlFor="contactName">Contact name </Label>
              </div>
              <Input name="contactName" type="text" id="contactName" required />
            </div>

            <div className="grid w-full items-center gap-1.5">
              <div className="space-y-3">
                <Typography size="tlg" className="font-bold text-gray-800">
                  Contact phone number
                </Typography>
                <Label htmlFor="newEmail">Contact phone number </Label>
              </div>
              <PhoneInput
                international
                focusInputOnCountrySelection
                value={value}
                // onChange={setValue}
                defaultCountry="US"
              />
            </div>
          </div>
          <div className="flex w-full flex-col items-start gap-y-4 md:flex-row md:gap-x-4 md:gap-y-0">
            <div className="grid w-full items-center gap-1.5">
              <div className="space-y-3">
                <Typography size="tlg" className="font-bold text-gray-800">
                  Confirm your password
                </Typography>
                <Label htmlFor="password">Password</Label>
              </div>
              <Input type="text" id="password" name="password" required />
            </div>
          </div>

          <div className="space-y-3">
            <Button
              size="xl"
              isLoading={loading}
              type="submit"
              className="w-[146px]"
            >
              Save changes
            </Button>
            <Typography size="txs" className="text-gray-500">
              If you added a new phone number, a 6-digit code will be sent via
              SMS to verify it.
            </Typography>
          </div>
        </form>
      </div>
    </div>
  );
}
