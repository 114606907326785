import { OrderReport, Report } from '@components/dashboard/admin-reports';
import routes from '@config/routes';
import { serverFetcher } from '@instance';
import devConsole from '@utils/developer-console';
import type {
  AgencyAdminResponse,
  AgencyAffiliateCode,
  AgencyPerformance,
  AgencyTeamMember,
  AgentDetailsResponse,
  AgentInviteResponse,
  UserAgencyDetailPerformance,
  UserAgencyDetails,
  UserAgencyResponse,
  UserAgencySales,
} from '@v2/types';
import { createSearchParams } from 'src/create-search-params';

export const getAgencyMe = async (token): Promise<UserAgencyResponse> => {
  try {
    return await serverFetcher(routes.agencyMe, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const getAgencyDetailsOverview = async ({
  agencyId,
  token,
}: {
  token: string;
  agencyId: number;
}): Promise<UserAgencyDetails> => {
  try {
    return await serverFetcher(routes.agencyDetails(agencyId), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const getAgencyPerformance = async ({
  agencyId,
  token,
}: {
  token: string;
  agencyId: number;
}): Promise<AgencyPerformance> => {
  try {
    return await serverFetcher(routes.agencyPerformance(agencyId), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};
export const getAgencyDetailsPerformance = async ({
  agencyId,
  token,
}: {
  token: string;
  agencyId: number;
}): Promise<UserAgencyDetailPerformance[]> => {
  try {
    return await serverFetcher(routes.agencyDetailedPerformance(agencyId), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};
export const getAgencyOrders = async ({
  agencyId,
  token,
}: {
  token: string;
  agencyId: number;
}): Promise<Report<OrderReport>> => {
  try {
    return await serverFetcher(routes.agencyReports(agencyId), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};
export const getAgencySales = async ({
  agencyId,
  token,
}: {
  token: string;
  agencyId: number;
}): Promise<UserAgencySales[]> => {
  try {
    return await serverFetcher(routes.agencySales(agencyId), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const getAgencyAffiliateCodes = async ({
  agencyId,
  token,
}: {
  token: string;
  agencyId: number;
}): Promise<AgencyAffiliateCode[]> => {
  try {
    return await serverFetcher(routes.agencyAffiliatesCodes(agencyId), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const getAgencyDiscountCode = async ({
  agencyId,
  token,
  codeId,
}: {
  token: string;
  agencyId: number;
  codeId: number;
}): Promise<AgencyAffiliateCode> => {
  try {
    return await serverFetcher(routes.agencyDiscountCode(agencyId, codeId), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

// AGENCYTEAM MEMBER
export const getAgencyTeamMembers = async ({
  agencyId,
  token,
  page = 1,
  query,
}: {
  token: string;
  agencyId: number;
  page: number;
  query?: string;
}): Promise<AgencyTeamMember> => {
  try {
    return await serverFetcher(
      `${routes.agencyTeamMember(agencyId)}${createSearchParams({ page, query })}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};
export const getAgencyTeamMemberInviteById = async (
  inviteId
): Promise<AgentInviteResponse> => {
  try {
    return await serverFetcher(routes.agencyTeamMemberInvite(inviteId), {
      method: 'GET',
      // headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const addAgencyTeamMember = async ({
  agencyId,
  token,
  body,
}: {
  token: string;
  body: string;
  agencyId: number;
}): Promise<any> => {
  try {
    return await serverFetcher(routes.agencyTeamMemberInvite(agencyId), {
      method: 'POSt',
      headers: { Authorization: `Bearer ${token}` },
      body,
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const getAgentDetailsUnderAgency = async ({
  agencyId,
  agentUserId,
  token,
}: {
  token: string;
  agencyId: number;
  agentUserId: number;
}): Promise<AgentDetailsResponse> => {
  try {
    return await serverFetcher(
      routes.agentDetailsUnderAgency({ agencyId, agentUserId }),
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (err) {
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const GetAgencies = async (
  token: string
): Promise<AgencyAdminResponse[]> => {
  try {
    return await serverFetcher(routes.agencyAdmin, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export const getAgencyTeamMemberDetails = async ({
  agencyId,
  token,
  page = 1,
  query,
}: {
  token: string;
  agencyId: number;
  page: number;
  query?: string;
}): Promise<AgencyTeamMember> => {
  try {
    return await serverFetcher(
      `${routes.agencyTeamMember(agencyId)}${createSearchParams({ page, query })}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    // @ts-ignore
    return { error: 'Something went wrong. Please try again later.' };
  }
};