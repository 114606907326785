import { Button, Typography } from '@v2/ui';
import { cn } from '@v2/utils/cn';
import React from 'react';

export function ContentContainer(props: {
  children: React.ReactNode;
  className?: string;
}) {
  const { children, className } = props;
  return (
    <div className={cn('flex w-full flex-col gap-y-10', className)}>
      {children}
    </div>
  );
}

export function ItemWrapper(props: {
  children: React.ReactNode;
  className?: string;
  title: string;
  trigger: React.ReactNode;
  headerClassName?: string;
}) {
  const { children, className, title, trigger, headerClassName } = props;
  return (
    <div className="w-full">
      <div
        className={cn(
          'mb-5 flex items-center justify-between',
          headerClassName
        )}
      >
        <Typography size="dxs" className="font-bold">
          {title}
        </Typography>
        {trigger}
      </div>

      <div
        className={cn(
          'flex w-full items-center justify-between border-b border-gray-200 pb-10',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
